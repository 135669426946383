const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            columnsData: [
                {
                    label: "县",
                    prop: "COUNTY"
                },
                {
                    label: "乡",
                    prop: "COUNTRY"
                },
                {
                    label: "村",
                    prop: "VILLAGE"
                },
                {
                    label: "监测时间",
                    prop: "JCRQ",
                    minWidth: 100,
                    substr: true
                }, {
                    label: "物种名称",
                    prop: "WZMC"
                },
                {
                    label: "监测点位",
                    prop: "JCDW"
                }, {
                    label: "经度",
                    prop: "JD"
                }, {
                    label: "纬度",
                    prop: "WD"
                }, {
                    label: "监测人员",
                    prop: "JCRY"
                },
                {
                    label: "叶芽开放期",
                    prop: "FYQ_YYKFQ"
                }, {
                    label: "花芽开放期",
                    prop: "FYQ_HYKFQ"
                },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;